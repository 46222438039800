.claim-section {
  padding-top: 41px;

  .upper-heading {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h6 {
      color: #fff;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.44px;
    }
    button {
      border: none;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
      display: flex;
      align-items: center;
      gap: 10px;
      background-color: transparent;
    }
  }

  .bottom-tabs {
    position: relative;
    .nav-tabs {
      border: none;
      border: 1px solid #fff;
      max-width: 501px;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 30px;

      .nav-item {
        flex: 1;

        .nav-link {
          width: 100%;
          padding: 20px 10px;
          background-color: transparent;
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 110%;
          border: none;
          border-radius: 0;
        }
      }
    }

    .nav-item.show .nav-link,
    .nav-link.active {
      background-color: #fff !important;
      color: #000 !important;
    }

    .twice-cards {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      .single-card {
        border: 1px solid #fff;
        background: #000;
        box-shadow: 3px 3px 0px 0px #fff;
        padding: 25px;
        height: 295px;
        flex: 0 0 24.4%;
        .gems-logo-img {
          width: 35px;
          height: 35px;
        }
        .up-text {
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          opacity: 0.6;
          margin-top: 20px;
          margin-bottom: 10px;
        }

        h6 {
          color: #fff;
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
          letter-spacing: -0.22px;
          margin-bottom: 10px;
        }

        .twice-text {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 20px;
          flex-wrap: wrap;

          p {
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.14px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 0;

            span {
              width: 3px;
              height: 14px;
              border-radius: 4px;
              background: #fff;
              display: flex;
              margin-right: 4px;
            }
          }
        }
      }
    }

    .inner-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      .custom-option-field {
        max-width: 750px;
        width: 100%;
      }

      .dropdown {
        // max-width: 108px;
        // width: 100%;
        // margin-bottom: 30px;
        .dropdown-toggle {
          padding: 14px;
          width: 100%;
          border: 1px solid #fff;
          background: #000;
          box-shadow: none;
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          width: 180px;

          &[aria-expanded="true"] {
            box-shadow: 4px 4px 0px 0px #fff;
          }

          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          border: 1px solid #fff;
          background: #000;
          box-shadow: 4px 4px 0px 0px #fff;

          a {
            padding: 12px 15px;
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border-bottom: 1px solid #f3f3f3;
            // &:last-child{
            //     border-bottom: 1px solid transparent;
            // }
          }
        }
      }
    }
    .allow-claim-btn {
      border: 1px solid #28e664;
      background: #000;
      box-shadow: 4px 4px 0px 0px #28e664;
      padding: 13px 25px;
      color: #28e664;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 16px */
      position: absolute;
      top: 0;
      right: 280px;
      &.disable {
        opacity: 0.4 !important;
      }
    }
    .revoke-claim-btn {
      border: 1px solid #fe0000;
      background: #000;
      box-shadow: 4px 4px 0px 0px #fe0000;
      padding: 13px 25px;
      color: #fe0000;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 16px */
      position: absolute;
      top: 0;
      right: 0;
      &.disable {
        opacity: 0.4 !important;
      }
    }
    .dropdown {
      // max-width: 170px;
      // width: 100%;
      // margin-bottom: 30px;
      .dropdown-toggle {
        padding: 15px;
        width: 100%;
        box-shadow: none;
        height: 46px;
        border: 1px solid #fff !important;
        background: #000 !important;
        border-radius: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;

        &[aria-expanded="true"] {
          box-shadow: 4px 4px 0px 0px #fff;
        }

        &::after {
          display: none;
        }
      }

      .dropdown-menu {
        border: 1px solid #fff;
        background: #000;
        box-shadow: 4px 4px 0px 0px #fff;

        a {
          padding: 12px 15px;
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border-bottom: 1px solid #f3f3f3;
          // &:last-child{
          //     border-bottom: 1px solid transparent;
          // }
          background: transparent;
        }
      }
    }
  }
  .btn-claimed {
    border: 1px solid #fff;
    padding: 15px 45px;
    background-color: transparent;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    &.disable {
      border: 1px solid #1e1e1e;
      color: #1e1e1e;
    }
  }
}

.claim-mobile-view {
  .inner-card {
    border: 1px solid #fff;
    background: #000;
    padding: 22px 25px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 19px;
    margin-bottom: 14px;
    .left-side {
      max-width: 30px;
      width: 100%;
    }
    .right-side {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 20px 0;
      .inner-text {
        flex: 0 0 46%;
        flex-grow: 1;
        p {
          color: #8e8e8e;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 8px;
        }
        h6 {
          color: #fff;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%; /* 12px */
        }
        .twice-text {
          h6 {
            color: #fff;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 12px */
          }
          p {
            color: #fff;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px; /* 175% */
            letter-spacing: 0.24px;
          }
        }
      }
    }
  }
}
.set-custom-claim-text {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 23px;
  h6 {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 17.6px */
  }
  .parent-toggle-switch-custom .checkbox:checked + .switch {
    background-color: #fe0000 !important;
  }
}

@media (max-width: 991px) {
  .mobileview-claim {
    display: block !important;
  }
  .desktop-btn {
    display: none !important;
  }
  .claim-section .bottom-tabs .nav-tabs .nav-item .nav-link {
    white-space: nowrap;
  }
  .claim-section .bottom-tabs .nav-tabs {
    flex-wrap: nowrap;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .claim-section .bottom-tabs .twice-cards {
    flex-direction: column;
    gap: 10px;
  }
  .claim-section .bottom-tabs .twice-cards .single-card {
    flex: 0 0 100%;
    width: 100%;
  }
  .claim-section .bottom-tabs .twice-cards .single-card .up-text {
    font-size: 14px;
    margin-top: 0;
  }
  .claim-section .bottom-tabs .twice-cards .single-card img {
    display: none;
  }
  .claim-section .bottom-tabs .inner-div {
    flex-wrap: wrap;
    gap: 15px;
  }
  .claim-section .bottom-tabs .inner-div .dropdown {
    max-width: 115px;
  }
  .mobile-btn {
    display: block !important;
    position: static;
    margin-bottom: 30px;
    max-width: 100%;
    width: 100%;
  }
  .claim-section .bottom-tabs .inner-div {
    gap: 10px;
  }
  .set-w-100 {
    flex: 0 0 100% !important;
  }
  .claim-section .btn-claimed {
    width: 100%;
  }
  .claim-section .upper-heading button {
    font-size: 14px;
  }
  .claim-section .upper-heading h6 {
    font-size: 18px;
  }
  .newclaiming-modal {
    padding: 10px 0 !important;
  }
  .newclaiming-modal .left-side h6 {
    font-size: 14px !important;
  }
  .newclaiming-modal .left-side p {
    font-size: 12px !important;
  }
  .download-table-xls-button {
    position: static !important;
  }
  .claim-section .bottom-tabs .allow-claim-btn {
    position: static !important;
  }
  .claim-section .bottom-tabs .revoke-claim-btn {
    position: static !important;
  }
}

@media (max-width: 396px) {
  .claim-section .bottom-tabs .inner-div .dropdown {
    max-width: 110px;
  }
}
@media (max-width: 380px) {
  .claim-section .bottom-tabs .inner-div .dropdown {
    max-width: 48%;
    flex: 0 0 48%;
  }
  .claim-section .bottom-tabs .nav-tabs .nav-item .nav-link {
    padding: 20px 0;
    font-size: 14px;
  }
}
@media (max-width: 365px) {
  .claim-section .bottom-tabs .inner-div .dropdown {
    max-width: 100px;
  }
}
.nAllowed {
  cursor: not-allowed !important;
  filter: brightness(0.7) !important;
  &::before {
    cursor: not-allowed !important;
    filter: brightness(0.7) !important;
  }
}

.claiming-modal {
  .modal-dialog {
    max-width: 524px;
  }
}
.newclaiming-modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 10px 20px;
  .left-side {
    h6 {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      margin-bottom: 10px;
    }
    p {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }
  }
}

.claim-modal {
  .modal-dialog {
    max-width: 524px;
  }
  .upper-div {
    border: 1px solid #fff;
    background: #000;
    padding: 24px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    p {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px; /* 150% */
      letter-spacing: 0.28px;
    }
    h6 {
      color: #fff;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 21px; /* 105% */
      letter-spacing: 0.4px;
    }
  }
  .bottom-div {
    margin-top: 35px;
    h6 {
      color: #fff;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 21px; /* 105% */
      letter-spacing: 0.4px;
    }
    p {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px; /* 150% */
      letter-spacing: 0.28px;
      margin: 12px 0;
    }
  }
  .buy-dop {
    .para {
      color: #fff;
      text-align: justify;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px; /* 150% */
      letter-spacing: 0.28px;
      margin: 35px 0;
    }
    .error-msg {
      color: rgb(250, 54, 54);
      text-align: justify;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px; /* 150% */
      letter-spacing: 0.28px;
      margin: 5px 0;
    }
    .twice-field {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .option-field {
        // max-width: 374px;
        width: 100%;
        height: 50px;
        position: relative;
        .material-textfield {
          margin-bottom: 0 !important;
        }
        a {
          position: absolute;
          top: 17px;
          right: 17px;
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 117%;
          text-transform: uppercase;
        }
      }
      .dropdown {
        max-width: 100px;
        width: 100%;
        height: 50px;
        .dropdown-toggle {
          border: 1px solid #fff;
          background: #000;
          padding: 10px;
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 100%; /* 14px */
          letter-spacing: 0.1px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
          width: 100%;
          &::after {
            display: none;
          }
        }
        .dropdown-menu {
          border: 1px solid #fff;
          background: #000;
          padding: 10px;
          padding: 0;
          width: 100%;
          border-radius: 0;
          min-width: 100%;
          a {
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%; /* 14px */
            letter-spacing: 0.1px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;
          }
        }
      }
    }
    .bottom-content {
      margin-top: 20px;
      margin-bottom: 35px;
      .textt {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        p {
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px; /* 131.25% */
          letter-spacing: 0.32px;
        }
        h6 {
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 21px; /* 131.25% */
          letter-spacing: 0.32px;
          max-width: 250px;
          width: 100%;
          text-align: right;
        }
      }
    }
  }
}

.buydop-modal {
  .twice-btn {
    button {
      flex: 0 0 48% !important;
    }
  }
}

@media (max-width: 600px) {
  .claim-modal .buy-dop .bottom-content .textt h6 {
    text-align: right;
  }
  .claim-modal .material-textfield label {
    white-space: nowrap;
    padding: 0 3.4rem 0 1.3rem;
  }
  .vstvcdvtvdvcd {
    width: 100%;
    max-width: 100% !important;
  }
  .claim-section .bottom-tabs .inner-div .dropdown .dropdown-toggle {
    width: 100%;
  }
  .claim-section .bottom-tabs .inner-div .dropdown {
    max-width: 48% !important;
    width: 100% !important;
  }
  .claim-section .bottom-tabs .inner-div .set-custom-type-dropdown-mobile {
    max-width: 100% !important;
    width: 100%;
  }
}

.download-table-xls-button {
  border: 1px solid #fff;
  background: #000;
  padding: 13px 25px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  position: absolute;
  top: 0;
  right: 550px;
  text-align: center;
  &:hover {
    color: white !important;
  }
}

.drop-allclaim {
  position: absolute;
  top: 0;
  right: 765px;
  button {
    color: #fff;
  }
}
.sjcbvysvs {
  button {
    color: #fff;
  }
}
.pending {
  color: #ff8300 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1px;
  vertical-align: middle !important;
  // border-top: 1px solid #fff;
  padding: 0;
  // padding: 30px 15px;
  white-space: nowrap;
  background-color: transparent;
}
.pending2 {
  color: #fe0000 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1px;
  vertical-align: middle !important;
  // border-top: 1px solid #fff;
  padding: 0;
  // padding: 30px 15px;
  white-space: nowrap;
  background-color: transparent;
}
.disable {
  filter: brightness(2) !important;
}

.set-new-style-claim {
  .upper-div {
    margin-top: 20px;
  }
  .modal-dialog {
    max-width: 690px !important;
  }
  .twice-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 35px;
    .parent-inner {
      flex: 0 0 48%;
      height: 243px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      .bottom-div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 0 !important;
        h6 {
          margin: 12px 0;
        }
        p {
          text-align: center;
          margin: 0 !important;
        }
        h5 {
          color: #fff;
          text-align: center;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0.2px;
          opacity: 0.8;
          margin-top: 22px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .twice-btn {
    width: 100% !important;
  }
  .btn-style-new {
    border: 1px solid #fff !important;
    background: #fff !important;
    box-shadow: none !important;
    color: #000 !important;
  }
  .btn-add {
    box-shadow: none !important;
  }
}

@media (max-width: 600px) {
  .set-new-style-claim .twice-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
  }
  .set-new-style-claim .twice-content .parent-inner {
    flex: 0 0 100%;
    height: auto;
  }
  .sccscscscsccs {
    margin-top: 25px;
  }
  .set-new-style-claim .upper-div h6 {
    text-align: center;
  }
}
