.salesround {
  padding: 60px 0;

  .main-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .parent-btns {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 20px;
    }

    h6 {
      color: #fff;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      /* 109.091% */
      letter-spacing: 0.44px;
    }

    button {
      border: 1px solid #fff;
      background: #000;
      box-shadow: 4px 4px 0px 0px #fff;
      padding: 13px 45px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      /* 16px */
    }

    .round-setting {
      border: none;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
      display: flex;
      align-items: center;
      gap: 10px;
      background-color: transparent;
    }
  }
}

.ysvtvstyvtiivtyvs {
  display: none;
}

.scuhvscycvst {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
}

.option-field {
  position: relative;

  .calendar-icon {
    position: absolute;
    top: 45px;
    right: 20px;
    cursor: pointer;
    pointer-events: none;
  }
}

.ijijijijij {
  // pointer-events: none;
  opacity: 0.5;
}

.material-textfield {
  margin-bottom: 14px !important;
}

.active-pag {
  background: #fff !important;
  color: #000 !important;
}

.ysvtvstyvtiivtyvs {
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
  visibility: hidden !important;

  td {
    opacity: 0 !important;
    width: 0 !important;
    height: 0 !important;
    visibility: hidden !important;
    padding: 0 !important;
  }
}

.add-mega-modal .option-field h5 {
  color: #fff;
  margin-bottom: 18px;
  font-size: 12px;
}

.add-mega-modal .option-field {
  margin-bottom: 18px !important;
}

@media (max-width: 991px) {
  .salesround .main-heading button {
    padding: 13px 15px;
    font-size: 14px;
  }

  .salesround .main-heading h6 {
    font-size: 18px;
  }
}

.opacc {
  opacity: 0.4;
  pointer-events: none;
}

.cdvtdvtvtvdcvtdvv .optionListContainer {
  position: relative !important;
}

.twice-inputssss {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 18px !important;

  .option-field {
    flex: 0 0 49%;
    margin-bottom: 0 !important;

    &:last-child {
      flex: 0 0 49%;
    }
  }
}

.add-mega-modal .twice-inputssss .option-field {
  margin-bottom: 0 !important;
}

.mybtnsforinput {
  border-radius: 0px;
  .saleroundinput {
    padding: 0px !important;
    border: none !important;
    outline: none !important;
  }
}
.menu-my {
  padding: 0px;
  overflow: auto;
  height: 200px;
  border-radius: 0px !important;

  &::-webkit-scrollbar {
    display: none;
  }

  .addtoken {
    background: transparent;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    padding: 14px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}

.cryptomain {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  .cryptomainimg {
    width: 18px;
    height: 18px;
    border-radius: 40px;
    object-fit: cover;
    object-position: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .cryptoinnerimg {
      width: 100%;
      height: 100%;
      border-radius: 40px;
      object-fit: cover;
      object-position: center;
    }
  }

  .cryptopara {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
