.allagents {
  padding: 60px 0;

  .main-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h6 {
      color: #fff;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      /* 109.091% */
      letter-spacing: 0.44px;
    }

    button {
      border: 1px solid #fff;
      background: #000;
      box-shadow: 4px 4px 0px 0px #fff;
      padding: 13px 45px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      /* 16px */
    }

    .rightside-content {
      max-width: 938px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .custom-option-field {
        max-width: 437px;
        width: 100%;
      }

      .dropdown {
        max-width: 170px;
        width: 100%;

        .dropdown-toggle {
          padding: 15px;
          width: 100%;
          box-shadow: none;
          height: 46px;

          &[aria-expanded="true"] {
            box-shadow: 4px 4px 0px 0px #fff;
          }

          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          border: 1px solid #fff;
          background: #000;
          box-shadow: 4px 4px 0px 0px #fff;

          a {
            padding: 12px 15px;
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border-bottom: 1px solid #f3f3f3;
            // &:last-child{
            //     border-bottom: 1px solid transparent;
            // }
            background: transparent;
          }
        }
      }
    }
  }
  .multi-items {
    max-width: 938px;
    width: 100%;
    .set-twice-text {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 15px;
      margin-bottom: 15px;
      h6 {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

.viewdetail {
  padding: 60px 0;

  .main-heading111 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    button {
      border: 1px solid #fff;
      background: #000;
      box-shadow: 4px 4px 0px 0px #fff;
      padding: 13px 45px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      /* 16px */
    }

    .rightside-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .custom-option-field {
        width: 91%;
      }

      .dropdown {
        max-width: 180px;
        width: 100%;

        .dropdown-toggle {
          padding: 17px;
          width: 100%;
          border: 1px solid #fff;
          background: #000;
          box-shadow: none;
          height: 46px;

          &[aria-expanded="true"] {
            box-shadow: 4px 4px 0px 0px #fff;
          }

          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          border: 1px solid #fff;
          background: #000;
          box-shadow: 4px 4px 0px 0px #fff;

          a {
            padding: 12px 15px;
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border-bottom: 1px solid #f3f3f3;
            // &:last-child{
            //     border-bottom: 1px solid transparent;
            // }
          }
        }
      }
    }
  }

  .main-heading2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    button {
      border: 1px solid #fff;
      background: #000;
      box-shadow: 4px 4px 0px 0px #fff;
      padding: 13px 45px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      /* 16px */
    }

    .rightside-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .custom-option-field {
        max-width: 950px;
        width: 100%;
      }

      .dropdown {
        max-width: 180px;
        width: 100%;

        .dropdown-toggle {
          padding: 17px;
          width: 100%;
          box-shadow: none;
          height: 46px;

          &[aria-expanded="true"] {
            box-shadow: 4px 4px 0px 0px #fff;
          }

          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          border: 1px solid #fff;
          background: #000;
          box-shadow: 4px 4px 0px 0px #fff;
          min-width: 258px;

          a {
            padding: 12px 15px;
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border-bottom: 1px solid #f3f3f3;
            // &:last-child{
            //     border-bottom: 1px solid transparent;
            // }
          }

          .custom-option-field {
            input {
              border: none;
            }
          }
        }
      }
    }
  }

  .main-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-back {
      border: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      gap: 10px;
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      /* 14px */
    }

    .twice-btn {
      display: flex;
      align-items: center;
      gap: 12px;

      .btn-edit {
        border: 1px solid #fff;
        background: #000;
        padding: 13px 30px;
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        /* 14px */
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .btn-del {
        border: 1px solid #fe0000;
        background: #000;
        padding: 13px 30px;
        color: #fe0000;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        /* 14px */
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }

  .inner-heading {
    margin: 20px 0;

    h4 {
      color: #fff;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      /* 38.4px */
    }
  }

  .bottom-cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;

    .single-card {
      border: 1px solid #fff;
      background: #000;
      box-shadow: 3px 3px 0px 0px #fff;
      height: 342px;
      flex: 0 0 19.4%;
      padding: 25px;
      .gems-logo-img {
        width: 35px;
        height: 35px;
      }
      p {
        margin-top: 15px;
        margin-bottom: 10px;
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        /* 19.2px */
        opacity: 0.6;
      }

      h6 {
        color: #fff;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        /* 26.4px */
        letter-spacing: -0.22px;
      }

      .bottom-text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        margin-top: 10px;
        flex-wrap: wrap;

        .uni-text {
          opacity: 1;
          display: flex;
          align-items: center;
          gap: 5px;
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          /* 16.8px */
          letter-spacing: -0.14px;
          margin: 0;

          span {
            background-color: #fff;
            border-radius: 4px;
            width: 3px;
            height: 14px;
            border-radius: 4px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  .parent-new-card {
    border: 1px solid #fff;
    background: #000;
    margin: 20px 0;
    padding: 30px;

    .main-headingg {
      h6 {
        color: #fff;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
        margin-bottom: 30px;
      }
    }
  }

  .bottom-cards1 {
    padding-right: 190px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 34px;

    .inner-text {
      flex: 0 0 240px;

      p {
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        /* 16.8px */
        opacity: 0.6;
        margin-bottom: 12px;
      }

      h6 {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        /* 19.2px */
      }
    }
  }

  .bottom-cards2 {
    margin-top: 30px;

    h4 {
      color: #fff;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
      /* 24.2px */
      margin-bottom: 30px;
    }

    .parent-card {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 34px 0;

      .inner-text {
        flex: 0 0 420px;
        min-height: 50px;
        max-height: 200px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          display: none;
        }

        p {
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          /* 16.8px */
          opacity: 0.6;
          margin-bottom: 12px;
        }

        .style-text {
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
          margin-bottom: 5px;
          text-decoration: underline;
          /* 19.2px */
        }

        h6 {
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
          margin-bottom: 5px;
          /* 19.2px */
        }
      }
    }
  }

  .bottom-cards3 {
    .parent-card .inner-text {
      flex: 0 0 310px !important;
    }
  }

  .viewdetail-tabs {
    max-width: 610px;
    width: 100%;
    justify-content: space-between;
    border: 1px solid #fff;

    .nav-item {
      flex: 1;

      .nav-link {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
        border: none;
        padding: 20px 10px;
        width: 100%;
      }
    }

    .nav-item.show .nav-link,
    .nav-link.active {
      background: #fff;
      border-radius: 0;
      border: none;
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
      /* 17.6px */
    }
  }
}

.delete-modal {
  .delete-modal-div {
    padding: 45px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h6 {
      color: #fff;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
      /* 22px */
      margin-top: 18px;
      margin-bottom: 12px;
    }

    p {
      color: #fff;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      /* 17.6px */
    }
  }

  .btn-confirm {
    border: 1px solid #fff;
    background: #000;
    box-shadow: 4px 4px 0px 0px #fff;
    padding: 15px;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 14px */
    text-transform: uppercase;
  }
}

.add-mega-modal {
  .add-mega-div {
    padding-top: 40px;

    h6 {
      color: #fff;
      text-align: center;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      /* 24.2px */
      padding-bottom: 40px;
    }
  }

  .option-field {
    margin-bottom: 35px;

    .dropdown {
      .dropdown-toggle {
        border: 1px solid #fff;
        background: #000;
        padding: 17px 18px;
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 117%;
        /* 16.38px */
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .dropdown-menu {
        border: 1px solid #fff;
        background: #000;
        box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.05);
        padding: 0;
        width: 100%;

        a {
          border-bottom: 1px solid #f3f3f3;
          background: rgba(255, 255, 255, 0.03);
          padding: 14px 15px;
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}

.allagents .main-heading .rightside-content .dropdown .gycytctdtrctrc {
  max-height: 400px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 991px) {
  .veiwdetaileinnerpage .view-btn {
    display: none !important;
  }

  .allagents .main-heading {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
  }

  .allagents .main-heading button {
    width: 100%;
  }

  // .viewdetail .main-heading .twice-btn {
  //   display: none;
  // }

  .viewdetail .main-heading .dropdown {
    display: block !important;
  }

  .viewdetail .dropdown {
    .dropdown-toggle {
      background-color: transparent;
      // border: none;

      &::after {
        display: none;
      }
    }

    .dropdown-menu {
      border: 1px solid #fff;
      background: #000;
      box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.05);
      padding: 0;

      a {
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        padding: 14px 15px;
      }
    }
  }

  .viewdetail .inner-heading h4 {
    font-size: 32px;
  }

  .viewdetail .inner-heading h4 span {
    font-size: 14px;
  }

  .viewdetail .bottom-cards .single-card {
    flex: 0 0 48.3% !important;
    height: 250px;
    padding: 15px;
  }

  .viewdetail .bottom-cards .single-card p {
    font-size: 12px;
  }

  .viewdetail .bottom-cards .single-card h6 {
    font-size: 16px;
  }

  .viewdetail .bottom-cards .single-card .bottom-text {
    gap: 15px;
    flex-wrap: wrap;
  }

  .viewdetail .bottom-cards .single-card .bottom-text .uni-text {
    font-size: 12px;
  }

  .viewdetail .bottom-cards1 {
    padding: 0;
    gap: 40px 0;
    justify-content: space-between;
  }

  .viewdetail .parent-new-card {
    padding: 25px;
  }

  .viewdetail .bottom-cards1 .inner-text {
    flex: 0 0 48%;
  }

  .viewdetail .bottom-cards1 .inner-text h6 {
    font-size: 14px;
    // max-width: 120px;
    width: 100%;
    // overflow: hidden;
    // text-overflow: ellipsis;
    word-break: break-all;
  }

  .allagents .main-heading .rightside-content {
    flex-wrap: wrap;
    gap: 15px;
  }

  .allagents .main-heading .rightside-content .dropdown {
    max-width: 100%;
    flex: 0 0 47%;
  }

  .viewdetail .main-heading111 .rightside-content {
    flex-direction: column;
    gap: 15px;
  }

  .viewdetail .main-heading111 .rightside-content .custom-option-field {
    width: 100%;
  }

  .viewdetail .main-heading111 .rightside-content .dropdown {
    max-width: 100%;
  }

  .viewdetail .main-heading111 .rightside-content .dropdown .dropdown-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .viewdetail .main-heading2 .rightside-content {
    flex-wrap: wrap;
    gap: 15px;
  }

  .viewdetail .main-heading2 .rightside-content .dropdown {
    max-width: 100%;
    flex: 0 0 47%;
  }
  .viewdetail .bottom-cards2 .parent-card .inner-text {
    flex: 0 0 46%;
  }
}

@media (max-width: 600px) {
  .viewdetail .bottom-cards .single-card h6 {
    word-break: break-all !important;
  }
  .viewdetail .main-heading {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
  }
  .viewdetail .main-heading .twice-btn {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    width: 100%;
  }
  .viewdetail .main-heading .twice-btn button {
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 376px) {
  .viewdetail .bottom-cards .single-card h6 {
    font-size: 12px !important;
  }

  .viewdetail .viewdetail-tabs .nav-item .nav-link {
    font-size: 14px;
  }
}

@media (max-width: 1300px) {
  .viewdetail .main-heading2 .rightside-content .custom-option-field {
    max-width: 1000px;
  }
}

.set-custom-scrollbar {
  max-height: 400px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.gvcvtdvdvtdcvdtc .custom-option-field {
  max-width: 90% !important;
}

.fxrxxrxxrexxr .custom-option-field {
  width: 100% !important;
}

@media (max-width: 991px) {
  .fxrxxrxxrexxr .custom-option-field {
    width: 100% !important;
  }
  .allagents .main-heading .rightside-content .custom-option-field {
    max-width: 100%;
  }
  .gvcvtdvdvtdcvdtc .custom-option-field {
    max-width: 100% !important;
  }
  .viewdetail .main-heading2 .rightside-content .dropdown {
    flex: 0 0 100% !important;
  }
}

.allldrop {
  max-width: 131px !important;
  width: 100%;
}

@media (max-width: 600px) {
  .allldrop {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
}

.specialaccess {
  padding-bottom: 20px;
}

.speacial-wrapper {
  .main-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h6 {
      color: #fff;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      /* 109.091% */
      letter-spacing: 0.44px;
    }

    button {
      border: 1px solid #fff;
      background: #000;
      box-shadow: 4px 4px 0px 0px #fff;
      padding: 13px 45px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      /* 16px */
    }

    .rightside-content {
      max-width: 697px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .custom-option-field {
        max-width: 503px;
        width: 100%;
      }

      .dropdown {
        max-width: 170px;
        width: 100%;

        .dropdown-toggle {
          padding: 15px;
          width: 100%;
          box-shadow: none;
          height: 46px;

          &[aria-expanded="true"] {
            box-shadow: 4px 4px 0px 0px #fff;
          }

          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          border: 1px solid #fff;
          background: #000;
          box-shadow: 4px 4px 0px 0px #fff;

          a {
            padding: 12px 15px;
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border-bottom: 1px solid #f3f3f3;
            // &:last-child{
            //     border-bottom: 1px solid transparent;
            // }
            background: transparent;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .speacial-wrapper .main-heading {
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
  }
  .speacial-wrapper .main-heading .rightside-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .speacial-wrapper .main-heading .rightside-content .dropdown {
    max-width: 100%;
  }
}

.allldrop {
  max-width: 126px !important;
  width: 100%;
}

@media (max-width: 600px) {
  .allldrop {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
}

.drop-in-search-sticky {
  position: sticky;
  top: -5px;
  left: 0;
  width: 100%;
  background: #000;
  z-index: 9;
}

.btnunblock {
  border: 1px solid #28e664 !important;
  color: #28e664 !important;
  svg path {
    fill: #28e664;
  }
}

.btnunblockmodal {
  background-color: #28e664 !important;
  color: #000 !important;
}

.bottom-cards33 {
  margin-top: 40px;
  h6 {
    color: #fff;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    margin-bottom: 23px;
  }
  p {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
}

.pagination li.active a {
  background-color: #fff !important;
  color: #000;
}
