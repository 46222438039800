.alltransaction {
  padding: 60px 0;

  .main-heading {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h6 {
      color: #fff;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      /* 109.091% */
      letter-spacing: 0.44px;
    }
    .set-twice-text {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 15px;
      margin-bottom: 15px;
      h6 {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .main-heading111 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    button {
      border: 1px solid #fff;
      background: #000;
      box-shadow: 4px 4px 0px 0px #fff;
      padding: 13px 45px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      /* 16px */
    }

    .rightside-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .custom-option-field {
        width: 91%;
      }

      .dropdown {
        max-width: 98px;
        width: 100%;

        .dropdown-toggle {
          padding: 15px;
          width: 100%;
          border: 1px solid #fff;
          background: #000;
          box-shadow: none;
          height: 46px;
          &[aria-expanded="true"] {
            box-shadow: 4px 4px 0px 0px #fff;
          }

          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          border: 1px solid #fff;
          background: #000;
          box-shadow: 4px 4px 0px 0px #fff;

          a {
            padding: 12px 15px;
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border-bottom: 1px solid #f3f3f3;
            // &:last-child{
            //     border-bottom: 1px solid transparent;
            // }
          }
        }
      }
    }
  }

  .bottom-cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;

    .single-card {
      border: 1px solid #fff;
      background: #000;
      box-shadow: 3px 3px 0px 0px #fff;
      height: 265px;
      flex: 0 0 32.8%;
      padding: 25px;
      .gems-logo-img {
        width: 35px;
        height: 35px;
      }
      p {
        margin-top: 15px;
        margin-bottom: 10px;
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        /* 19.2px */
        opacity: 0.6;
      }

      h6 {
        color: #fff;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        /* 26.4px */
        letter-spacing: -0.22px;
      }

      .bottom-text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        margin-top: 10px;
        flex-wrap: wrap;

        .uni-text {
          opacity: 1;
          display: flex;
          align-items: center;
          gap: 5px;
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          /* 16.8px */
          letter-spacing: -0.14px;
          margin: 0;

          span {
            background-color: #fff;
            border-radius: 4px;
            width: 3px;
            height: 14px;
            border-radius: 4px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  .custom-option-field {
    position: relative;
    .transaction-inner-drop {
      position: absolute;
      top: 0;
      left: 0;
      max-width: 130px !important;
      // padding-right: 25px;
      border-right: 1px solid #fff;
      .dropdown-toggle {
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;
      }
    }
    input {
      padding-left: 158px;
    }
  }
}

@media (max-width: 991px) {
  .dropdown-menu {
    width: 100%;
  }
  .alltransaction .bottom-cards .single-card {
    flex: 0 0 48%;
    min-height: 215px;
    height: 100%;
    padding: 15px;
    &:first-child {
      flex: 0 0 100%;
    }
  }

  .alltransaction .bottom-cards .single-card p {
    font-size: 12px;
  }
  .alltransaction .bottom-cards .single-card h6 {
    font-size: 16px;
  }
  .alltransaction .bottom-cards .single-card .bottom-text .uni-text {
    font-size: 12px;
  }
  .alltransaction .bottom-cards .single-card .bottom-text {
    flex-wrap: wrap;
    gap: 15px;
  }
  .alltransaction .bottom-cards .single-card img {
    display: none;
  }
  .alltransaction .main-heading111 .rightside-content {
    flex-direction: column;
    gap: 15px;
  }
  .alltransaction .main-heading111 .rightside-content .custom-option-field {
    width: 100%;
  }
  .alltransaction .main-heading111 .rightside-content .dropdown {
    max-width: 100%;
  }
  .alltransaction
    .main-heading111
    .rightside-content
    .dropdown
    .dropdown-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 376px) {
  .alltransaction .bottom-cards .single-card h6 {
    font-size: 12px !important;
  }
}

@media (max-width: 600px) {
  .alltransaction .bottom-cards .single-card h6 {
    word-break: break-all !important;
  }
  .alltransaction .custom-option-field .transaction-inner-drop {
    max-width: 115px !important;
  }
  .alltransaction .custom-option-field input {
    padding-left: 133px;
  }
}

.new-transaction {
  .main-heading111 .rightside-content .custom-option-field {
    width: 100% !important;
  }
}
